<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>{{ $t('hotelList') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="list">
        <div class="list-item" v-for="(hotel, index) of hotels" :key="index">
          <van-row type="flex" gutter="8">
            <van-col span="8">
              <van-image height="100" lazy-load fit="cover" :src="hotel.image" />
            </van-col>
            <van-col span="16">
              <div class="hotel-name">
                <div class="name">{{ hotel.name }}</div>
                <div class="rate">{{ hotel.currency_code }}{{ hotel.rate_amount | formatNumber }}</div>
              </div>
              <div class="address">{{ hotel.address }}</div>
              <div class="show-more">
<!--                <van-rate-->
<!--                  v-model="hotel.star"-->
<!--                  :size="16"-->
<!--                  color="#ffd21e"-->
<!--                  void-icon="star"-->
<!--                  void-color="#eee"-->
<!--                  readonly-->
<!--                />-->
                <van-button type="warning" size="small" @click="showMore(hotel)">{{ $t('showMore') }}</van-button>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
import Header from '@/components/Header'

export default {
  name: 'HotelList',
  components: {
    Header
  },
  data () {
    return {
      hotels: []
    }
  },
  computed: {
  },
  async mounted () {
    try {
      const res = await this.$axios.get('/invitation/hotel-list')
      this.hotels = res.data
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    showMore (hotel) {
      this.$router.push({
        name: 'HotelDetail',
        params: {
          hotelId: hotel.id,
          arrival: this.$route.params.arrival,
          departure: this.$route.params.departure,
          guests: this.$route.params.guests
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
}

.list {
  padding: 14px;

  .list-item:not(:first-child) {
    border-top: #dedede 1px solid;
    padding-top: 10px;
  }
}

.hotel-name {
  display: flex;
  flex-direction: row;
  .name {
    flex: 1;
    font-size: 16px;
    padding: 6px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.address {
  color: #5c5c5c;
  font-size: 14px;
  word-wrap: break-word;
  text-align: left;
}

.show-more {
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* 隐藏星级 */
  align-items: center;
}
</style>
